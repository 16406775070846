const mapStateToProps = (state) => {
    return {
        isloggedIn: state.isloggedIn,
        tradingType: state.tradingType,
        tradingBalance: state.tradingBalance,
        futureSymbol: state.futureSymbol,
        futureSymbolList: state.futureSymbolList,
        allSymbolList: state.allSymbolList,
        futureOrderPrice: state.futureOrderPrice,
        alert: state.alert,
        allOders: state.allOders,
        positions: state.positions,
        stopLossPrice: state.stopLossPrice,
        takeProfitPrice: state.takeProfitPrice,
        symbolListPrice: state.symbolListPrice,
        amountPercentage: state.amountPercentage,
        futureTrades: state.futureTrades,
        topCoin: state.topCoin,
        notification: state.notification,
    };
};

const mapDispatchToProps = (dispatch) => ({

    setIsloggedIn: data => dispatch({
        type: "IS_LOGGED_IN",
        payload: data
    }),
    setTradingType: data => dispatch({
        type: "TRADING_TYPE",
        payload: data
    }),
    setTradingBalance: data => dispatch({
        type: "TRADING_BALANCE",
        payload: data
    }),

    setFutureSymbol: data => dispatch({
        type: "FUTURE_SYMBOL",
        payload: data
    }),
    setFutureSymbolList: data => dispatch({
        type: "SYMBOL_LIST",
        payload: data
    }),

    setAllSymbolList: data => dispatch({
        type: "ALL_SYMBOL_LIST",
        payload: data
    }),
    setFutureOrderPrice: data => dispatch({
        type: "FUTURE_ORDER_PRICE",
        payload: data
    }),
    setAlert: data => dispatch({
        type: "ALERT",
        payload: data
    }),
    setFutureTrades: data => dispatch({
        type: "FUTURE_TRADES",
        payload: data
    }),

    setStopLossPrice: data => dispatch({
        type: "STOPLOSS_PRICE",
        payload: data
    }),
    setTakeProfitPrice: data => dispatch({
        type: "TAKEPROFIT_PRICE",
        payload: data
    }),
    setSymbolListPrice: data => dispatch({
        type: "SYMBOL_LIST_PRICE",
        payload: data
    }),
    setAmountPercentage: data => dispatch({
        type: "AMOUNT_PERCENTAGE",
        payload: data
    }),

    setNotification: data => dispatch({
        type: "NOTIFICATION",
        payload: data
    }),
    setTopCoin: data => dispatch({
        type: "TOP_COIN",
        payload: data
    }),
});


export { mapStateToProps, mapDispatchToProps };