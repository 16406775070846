import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LogInfoModal } from '../index';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { ConfirmationModal } from '../index';
import { changeSymbol } from '../../../utils';


const TradingTab = (props) => {
  const [closePrice, setClosePrice] = useState({});
  const [clickedTradeId, setClickedTradeId] = useState('');
  const [clickedDate, setclickedDate] = useState(new Date());

  const [confirmationInfo, setConfirmationInfo] = useState({
    title: null,
    body: null,
    function: function init() {
    },
    params: {},
  });

  useEffect(() => {
    (async () => {
      setInterval(async () => {
        await Promise.all([
          (async () => {
            await refreshTrades();
          })()
        ]);
      }, CONFIG.REFRESH_INTERVAL.TRADE);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([
        (async () => {
          await refreshTrades();
        })()
      ]);

    })();
  }, [props.tradingType]);

  const getDecimalNum = (value) => {
    if (value === 0) {
      return 1;
    }
    let decimal = value.toString().split('.')[1];
    if (!decimal) {
      return 0;
    }
    return decimal.length;
  };

  const toFixedNumber = (num, digits, base) => {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  };

  const closeTrades = async (item) => {
    await apis.dataSync('api_trading_close_trade', {
      trading_type: props.tradingType,
      'id': item.id,
      'close_price': item.close_price
    });

    setTimeout(async () => {
      await refreshTrades();
    }, 2000);
  };

  const replaceSymptoms = (t) => {
    if (!t) {
      return '';
    }
    return t.replace('EXECUTED', 'EXEC')
      .replace('CLEARING', 'CLEAR')
      .replace('CLOSED', 'CLOSE')
      .replace('ORDERED', 'ORDER')
      .replace('OPEN_ORDER', 'OPEN')
      .replace('STOP_LOSS', 'STOP')
      .replace('TAKE_PROFIT', 'PROFIT')
      .replace('PARTIALLY_FILLED', 'P_FILLED');
  };

  const updateClosePrice = (e, id) => {

    let newPrice;
    let price = closePrice[id];

    switch (true) {
      case (e._reactName === 'onWheel' && e.target.value && price > 0 && (e.deltaY > 0 || e.deltaY < 0)):
        let decimal = getDecimalNum(price);
        while ((price * 0.001).toFixed(decimal) == 0) {
          decimal = decimal + 1;
        }
        if (e.deltaY < 0) {
          newPrice = toFixedNumber(price * 1.001, decimal);
        } else if (e.deltaY > 0) {
          newPrice = toFixedNumber(price * 0.999, decimal);
        }
        setClosePriceById(id, newPrice);
        break;

      case (e._reactName === 'onInput' && e.nativeEvent.detail === undefined):
        e.target.value = price;
        break;

      case (e._reactName === 'onInput' && e.nativeEvent.detail === 0):
        newPrice = parseFloat(e.target.value);
        setClosePriceById(id, newPrice);
        break;
      default:
        return;
    }
  };

  const setClosePriceById = (id, value) => {
    let obj = {};
    obj[id] = value;
    setClosePrice({ ...closePrice, ...obj });

  };

  const refreshTrades = async () => {
    let data = await apis.dataSync('api_trading_get_trades', { trading_type: props.tradingType });
    props.setFutureTrades(data);
  };

  return (
    <Fragment>
      <ConfirmationModal confirmationInfo={confirmationInfo}></ConfirmationModal>
      <LogInfoModal id={clickedTradeId} date={clickedDate}></LogInfoModal>
      <Container>


        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th
                style={{ cursor: 'default' }}
                onClick={() => {
                  refreshTrades();
                }}
              >TRADES</th>
              <th>INFO</th>
              <th>STATUS</th>
              <th>PRICE</th>
              <th>PROFIT</th>
              <th
                style={{ textAlign: 'center' }}
              >X</th>
            </tr>
          </thead>
          <tbody>
            {props.futureTrades.map((item) => {
              let priceChangePercentage = <td>{item.price_change_percentage}</td>;
              let inputPrice = <td
                onClick={(e) => {
                  setClosePriceById(item.trade_id, item.order_price);
                }}
              >{item.order_price}</td>;
              let removeIcon = <td></td>;
              let info = replaceSymptoms(item.order_close_method);

              if (item.type == 'TRADE_DATA') {
                info = item.order_amount_usdt + '[U]';

                removeIcon = <td
                  style={{ textAlign: 'center' }}
                  onClick={(e) => {
                    item.close_price = closePrice[item.trade_id] || 0;
                    if (item.close_price === 0 && item.status === 'EXECUTED') {
                      setConfirmationInfo({
                        title: `ARE YOU SURE?`,
                        body: <p>CLOSE <b>{item.symbol}-{item.position_side}</b>: <span style={{ color: item.trade_profit >= 0 ? '#26a69a' : '#f44336' }}><b><u>{item.trade_profit}</u></b></span>  [USDT]</p>,
                        function: closeTrades,
                        params: item,
                      });
                    } else {
                      closeTrades(item);
                    }
                  }}><FontAwesomeIcon icon={faWindowClose} /></td>;
                priceChangePercentage = <td
                  onClick={(e) => {
                    setClosePriceById(item.trade_id, 0);
                  }}
                  style={{ color: item.price_change_percentage >= 0 ? '#26a69a' : '#f44336' }}><b>{item.price_change_percentage}{item.price_change_percentage ? '%' : ''}</b></td>;

                inputPrice = <td>
                  <InputGroup size='sm' className='mb-0'>
                    <FormControl aria-label='Small' aria-describedby='inputGroup-sizing-sm'
                      style={{ width: '60px', padding: '1px', margin: '0px', borderRadius: '0' }}
                      type='number'
                      step='any'
                      onMouseEnter={(e) => {
                        disableBodyScroll(document.querySelector('body'));
                      }}
                      onMouseLeave={(e) => {
                        enableBodyScroll(document.querySelector('body'));
                      }}
                      onClick={(e) => {
                        updateClosePrice(e, item.trade_id);
                      }}
                      onInput={(e) => {
                        updateClosePrice(e, item.trade_id);
                      }}
                      onWheel={(e) => {
                        updateClosePrice(e, item.trade_id);
                      }}
                      value={closePrice[item.trade_id]}
                    />
                  </InputGroup>
                </td>;
              }

              if (item.type == 'ORDER_DATA' && ['STOP_LOSS', 'TAKE_PROFIT'].includes(item.order_close_method)) {
                removeIcon = <td
                  style={{ textAlign: 'center' }}
                  onClick={(e) => {
                    item.close_price = 0;
                    closeTrades(item);
                  }}><FontAwesomeIcon icon={faTrash} /></td>;
              }


              return <tr key={item.id} >
                {item.type == 'TRADE_DATA' ? <td style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined, color: item.position_side === 'LONG' ? '#26a69a' : '#f44336' }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td> : <td></td>}
                <td
                  onClick={() => {
                    setClickedTradeId(item.id);
                    setclickedDate(new Date());
                  }}
                >{info}</td>
                <td>{replaceSymptoms(item.status)}</td>
                {inputPrice}
                {priceChangePercentage}
                {removeIcon}
              </tr>;
            })}
          </tbody>
        </Table>

      </Container>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(TradingTab);
