import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { changeSymbol } from '../../../utils';

const SymbolList = (props) => {

  useEffect(() => {
    (async () => {
      props.setFutureSymbolList(await apis.dataSync('api_trading_get_symbols', { trading_type: props.tradingType }));
    })();
  }, [props.tradingType]);

  return (
    <Fragment>
      <Row>
        <Col className='d-grid gap-2 col-6' >
          <ButtonGroup vertical>
            {props.futureSymbolList.slice(0, Math.round(props.futureSymbolList.length / 2)).map((item, idx) => {
              return <ToggleButton
                key={idx}
                size='sm'
                id={`symbol-${idx}`}
                type='radio'
                variant={'outline-light'}
                value={item}
                checked={props.futureSymbol === item}
                onChange={async (e) => {
                  await changeSymbol(props, item);
                }}
              >
                {item.slice(0, 10)}
              </ToggleButton>;
            })}

          </ButtonGroup>
        </Col>
        <Col className='d-grid gap-2 col-6'>
          <ButtonGroup vertical>
            {props.futureSymbolList.slice(Math.round(props.futureSymbolList.length / 2), props.futureSymbolList.length).map((item, idx) => {
              idx = idx + Math.round(props.futureSymbolList.length / 2);
              return <ToggleButton
                key={idx}
                size='sm'
                id={`symbol-${idx}`}
                type='radio'
                variant={'outline-light'}
                value={item}
                checked={props.futureSymbol === item}
                onChange={async (e) => {
                  await changeSymbol(props, item);
                }}
              >
                {item.slice(0, 10)}
              </ToggleButton>;
            })}
          </ButtonGroup>
        </Col>
      </Row>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolList);
