import React, { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import apis from '../../../apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

const ChartButton = (props) => {

  return (
    <Fragment>
      <Button
        variant='primary'
        onClick={() => {
          let screenWidth = window.screen.availWidth - CONFIG.TOOL_WINDOW_WIDTH;
          let screenHeight = window.screen.availHeight;

          window.open(window.location.origin + "/chart/" + props.tradingType, "", `width=${screenWidth}, height=${screenHeight}`);
        }}
      ><FontAwesomeIcon icon={faChartBar} /></Button>{' '}
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(ChartButton);
