import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import { useHistory } from "react-router-dom";
import apis from '../../../apis';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { Form, Navbar, Col, Row, Container, Nav, Button } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';


let toolWindow = null;
let chartWindow = null;
const screenWidth = window.outerWidth;
const screenHeight = window.outerHeight;
let chartWindowWidth;

const MainButton = (props) => {
    const history = useHistory();

    const createWindow = async () => {

        let toolWindowWidth = CONFIG.TOOL_WINDOW_WIDTH;
        chartWindowWidth = screenWidth - toolWindowWidth;


        if (!chartWindow && CONFIG.NODE_ENV != 'development') {
            chartWindow = window.open(`https://www.tradingview.com/chart/${CONFIG.CHART_NAME}/`, "", `width=${chartWindowWidth}, height=${screenHeight}`);
            chartWindow.resizeTo(chartWindowWidth, screenHeight);
            chartWindow.moveTo(0, 0);
        }


        if (!toolWindow) {
            toolWindow = (window.open(window.location.href + 'trading', "", `width=${toolWindowWidth}, height=${screenHeight}`));
        }

        if (toolWindow) {
            toolWindow.resizeTo(toolWindowWidth, screenHeight);
            toolWindow.moveTo(screenWidth * 2 - toolWindowWidth + 160, 0);
            toolWindow.focus();
        }

        if (chartWindow) {
            chartWindow.focus();
        }
    };


    const closeAllWindow = () => {
        if (toolWindow) {
            toolWindow.close();
            toolWindow = null;
        }
        if (chartWindow) {
            chartWindow.close();
            chartWindow = null;
        }
    };


    const changeChartWindowSymbol = () => {
        let symbol = localStorage.getItem('symbol');
        let indexSymbol = localStorage.getItem('index_symbol');

        if (symbol && chartWindow) {
            if (indexSymbol != symbol) {
                localStorage.setItem('index_symbol', symbol);
                chartWindow.location.replace(`https://www.tradingview.com/chart/${CONFIG.CHART_NAME}?symbol=${symbol}`, "", `width=${chartWindowWidth}, height=${screenHeight}`);
            }
            localStorage.removeItem('symbol');

        }
    };

    const checkIfWindowClosed = () => {
        if ((toolWindow && (toolWindow.closed)) || ((chartWindow && chartWindow.closed))) {
            closeAllWindow();
        }
    };

    // useEffect(() => {
    //     setInterval(() => {
    //         checkIfWindowClosed();
    //         changeChartWindowSymbol();

    //     }, 100);
    // }, []);


    return (
        <Fragment>
            <Container fluid="sm" style={{ marginTop: '30vh' }}>

                <div className=" d-flex align-items-center justify-content-center" >
                    <h1 style={{ color: 'white' }}>AUTOBOT</h1>
                </div>
                <div className=" d-flex align-items-center justify-content-center" >
                    <p className="lead" style={{ color: 'white' }}>Crypto trading system integrated with your Binance account</p>
                </div>
                <div className=" d-flex align-items-center justify-content-center" >
                    <BrowserView>
                        <Button
                            style={{ margin: '0px 10px', backgroundColor: 'white', color: 'black', fontWeight: 'bold' }}
                            size='lg'
                            variant="secondary"
                            onClick={(event) => {
                                event.preventDefault();

                            }}
                        >
                            Youtube Live
                        </Button>


                        <Button
                            style={{ margin: '0px 10px', border: '1px solid white', fontWeight: 'bold' }}
                            size='lg'
                            variant="secondary"
                            onClick={(event) => {
                                event.preventDefault();
                                createWindow();
                            }}
                        >
                            Trading Tool
                        </Button>
                    </BrowserView>
                    <MobileView>

                        <Button variant="warning"
                            onClick={() => {
                                history.push("/trading");
                            }}
                        >
                            TRADING
                        </Button>
                    </MobileView>
                </div>
            </Container>
        </Fragment>
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(MainButton);
