import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

const TradeButton = (props) => {

  useEffect(() => {
    (async () => {

    })();
  }, []);

  const [disableButton, setDisableButton] = useState(false);

  const createNewTrade = async (positionSide) => {
    if (!props.futureSymbol || !props.amountPercentage) {
      return;
    }
    setDisableButton(true);
    let usdtBalance = parseFloat(props.tradingBalance) || 200;

    let data = {
      trading_type: props.tradingType,
      'position_side': positionSide,
      'order_symbol': props.futureSymbol,
      'order_amount': parseFloat((props.amountPercentage * usdtBalance / 100).toFixed(2)),
      'order_price': props.futureOrderPrice || 0,
      'stop_loss_price': props.stopLossPrice || 0,
      'take_profit_price': props.takeProfitPrice || 0,
    };

    let trade = await apis.dataSync('api_trading_create_trade', data);

    if (trade) {
      console.log(trade);
      props.setAlert({
        date: new Date(),
        type: 'success',
        content: `Successfully place trade! ${trade['symbol']}-${trade['position_side']}!`,
      });
    }

    setDisableButton(false);

    setTimeout(async () => {
      props.setFutureTrades(await apis.dataSync('api_trading_get_trades', { trading_type: props.tradingType }));
    }, 2000);
  };

  const createAlerts = async () => {
    if (!props.futureSymbol || !props.futureOrderPrice) {
      return;
    }

    await apis.dataSync('api_future_create_alert', {
      'chart_symbol': props.futureSymbol,
      'triggered_price': props.futureOrderPrice,
    });

    props.setNotification(await apis.dataSync('api_trading_get_alerts'));
  };

  const jsonToReactElement = (response) => {
    return <>
      {Object.entries(response).map(([key, value], index) => <div>{key}:  {value}</div>)}
    </>;
  };
  return (
    <Fragment>
      <Col className='d-grid gap-2'>
        <Button
          variant='success'
          size='sm'
          onClick={(e) => {
            createNewTrade('LONG');
          }}
          disabled={disableButton}
        >{(props.tradingType == 'FUTURE') ? 'LONG' : 'BUY'}</Button>
      </Col>

      {(props.tradingType == 'FUTURE') ? <Col className='d-grid gap-2'>
        <Button
          variant='danger'
          size='sm'
          onClick={(e) => {
            createNewTrade('SHORT');
          }}
          disabled={disableButton}
        >SHORT</Button>
      </Col> : <></>

      }
      <Col className='d-grid gap-2'>
        <Button
          variant='warning'
          size='sm'
          onClick={(e) => {
            createAlerts();
          }}
        >NOTIFY</Button>
      </Col>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(TradeButton);
