let CONFIG = {
    CHART_NAME: 'wjvyOKiv',
    // API_ENDPOINT: window.location.origin,
    // API_ENDPOINT: 'https://autobotapp0712.herokuapp.com',
    API_ENDPOINT: (process.env.NODE_ENV === 'development') ? 'http://localhost:5000' : 'https://autobotapi.dialogix.app',
    // API_ENDPOINT: 'https://autobot.cloudns.asia',
    TOOL_WINDOW_WIDTH: 430,
    AMOUNT_PERCENTAGE: {
        LIST: [1, 2, 5],
        DEFAULT: 5
    },
    LEVERAGE: {
        LIST: [10, 20],
        DEFAULT: 20
    },
    REFRESH_INTERVAL: {
        POSITION: 30 * 1000,// 30 seconds
        ORDER: 5 * 60 * 1000, // 5 minutes
        TRADE: 10 * 60 * 1000, // 10 minutes
        HISTORY: 30 * 60 * 1000, // 30 minutes
        BALANCE: 10 * 60 * 1000, // 10 minutes
        SYMBOL_PRICE: 60 * 1000, //  1 minutes
        NOTIFICATION: 5 * 60 * 1000, //  5 minutes
    },
    MAX_SYMBOL_IN_LIST: 12,
    AES_SECRET: 'EHLlQUf81OZKeIId',
    NODE_ENV: process.env.NODE_ENV,
};

export default CONFIG;
