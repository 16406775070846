import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import { useHistory } from "react-router-dom";
import apis from '../../../apis';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { Form, Navbar, Col, Row, Container, Nav, Button } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';


const NavigationBar = (props) => {

    return (
        <Fragment>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container style={{ maxWidth: '800px' }}>
                    <Navbar.Brand href="/"><h2 style={{ cursor: 'inherit' }}>AUTOBOT</h2></Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>

                        <Nav >
                            <Nav.Link href="/">LOGIN</Nav.Link>
                            <Nav.Link href="/signup">SIGNUP</Nav.Link>
                            <Nav.Link href="">PRODUCTS</Nav.Link>
                            <Nav.Link variant="secondary"

                                onClick={() => {
                                    localStorage.removeItem('token');
                                    props.setIsloggedIn(false);
                                }}
                            >
                                LOGOUT
              </Nav.Link >
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
