import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import CONFIG from '../../../configs/index';
import { changeSymbol } from '../../../utils';

const SymbolSearch = (props) => {
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [symbolSearchValue, setSymbolSearchValue] = useState('');

  useEffect(() => {
    (async () => {
      props.setAllSymbolList(await apis.dataSync('api_trading_get_all_symbols', { trading_type: props.tradingType }));
    })();
  }, [props.tradingType]);

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={{ style, maxHeight: '250px', overflow: 'scroll' }}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className='list-unstyled'>
            {React.Children.toArray(children).filter(
              (child) =>
                !symbolSearchValue || child.props.children.toLowerCase().includes(symbolSearchValue.toLowerCase()),
            )}
          </ul>
        </div>
      );
    },
  );

  return (
    <Fragment>
      <InputGroup size='sm' className='mb-2'>
        <FormControl
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
          placeholder='SYMBOL...'
          onChange={(e) => {
            setShowSearchFilter(true);
            if (e.target.value.length === 0) {
              setShowSearchFilter(false);
            }
            setSymbolSearchValue(e.target.value);
          }}
          value={symbolSearchValue}
        />
      </InputGroup>

      <Dropdown show={showSearchFilter}>
        <Dropdown.Menu as={CustomMenu} variant="dark">
          {props.allSymbolList.map((item, idx) => {
            return <Dropdown.Item
              key={item}
              size='sm'
              variant='success'
              id={`all-symbol-${idx}`}
              value={item}
              active={props.futureSymbolList.includes(item)}
              onClick={async () => {
                setSymbolSearchValue('');
                setShowSearchFilter(false);
                await changeSymbol(props, item);
              }}
            >
              {item}
            </Dropdown.Item>;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(SymbolSearch);
