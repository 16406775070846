import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Alert, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

let timeout;
const AlertComponent = (props) => {

  useEffect(() => {
    (() => {
      if (props.alert && props.alert['content']) {
        showAlert();
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          hideAlert();
        }, 3000);

      }
    })();
  }, [props.alert]);

  const [alertVisibility, setAlertVisibility] = useState('hidden');

  const showAlert = () => {
    setAlertVisibility('visible');
  };
  const hideAlert = () => {
    setAlertVisibility('hidden');
  };

  return (
    <Fragment >
      <Row>
        <Container >
          <Container style={{ position: 'fixed', bottom: 0, paddingLeft: 0 }}>
            <Alert style={{ visibility: alertVisibility }} variant={props.alert['type']}>
              <p className="mb-0">
                {props.alert['content']}
              </p>
            </Alert>
          </Container>
        </Container>
      </Row>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
