import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import { useHistory } from "react-router-dom";
import apis from '../../../apis';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { Form, Navbar, Col, Row, Container, Nav, Button } from 'react-bootstrap';

const LoginForm = (props) => {
    const history = useHistory();

    const [inputs, setInputs] = useState({
        email: localStorage.getItem('email'),
        password: localStorage.getItem('password'),
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await apis.dataSync('api_login', {
            email: inputs.email,
            password: inputs.password
        });

        if (response) {
            props.setIsloggedIn(true);
            localStorage.setItem('token', response.token);

            localStorage.setItem('email', inputs.email);
            localStorage.setItem('password', inputs.password);
        }
    };


    return (
        <Fragment>
            <Container fluid="sm" style={{ marginTop: '20vh' }}>

                <div className=" d-flex align-items-center justify-content-center" >
                    <Form style={{ width: '400px' }}>
                        <Form.Label style={{ color: 'white' }}><h1>LOGIN</h1></Form.Label>
                        <h5 style={{ color: 'white' }}>Welcome to Autobot</h5>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                name='email'
                                size='lg'
                                value={inputs.email}
                                onChange={handleChange.bind(this)}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name='password'
                                size='lg'
                                value={inputs.password}
                                onChange={handleChange.bind(this)}
                            />
                        </Form.Group>
                        <hr ></hr>
                        <div className="d-grid gap-2">
                            <Button variant="secondary" size='lg' type="submit" onClick={handleSubmit}>
                                LOGIN
                            </Button>
                        </div>
                        <hr></hr>
                        <Form.Label style={{ color: 'white', fontSize: '12pt' }} onClick={() => {
                            history.push("/signup");
                        }}><p>Not a member? Sign Up</p></Form.Label>


                    </Form>
                </div>

            </Container>
        </Fragment>
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
