import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faInfoCircle, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CoinInfoModal from '../CoinInfoModal';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { changeSymbol } from '../../../utils';


const TopCoinTab = (props) => {
  const [infoSymbol, setInfoSymbol] = useState('');
  const [clickedDate, setclickedDate] = useState(new Date());
  useEffect(() => {
    (async () => {
      await refreshPage();
    })();

    setInterval(async () => {
      await refreshPage();
    }, CONFIG.REFRESH_INTERVAL.NOTIFICATION);
  }, []);

  const refreshPage = async () => {
    let data = await apis.dataSync('api_trading_get_change_24h');
    if (data) {
      props.setTopCoin(data);
    }
  };

  return (
    <Fragment>
      <Container>
        <CoinInfoModal symbol={infoSymbol} date={clickedDate}></CoinInfoModal>
        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th
                style={{ cursor: 'default' }}
                onClick={() => {
                  refreshPage();
                }}
              >SYMBOL</th>
              <th>24H</th>
              <th>NAME</th>
              <th>TAGS</th>
              <th>INFO</th>
            </tr>
          </thead>
          <tbody>
            {props.topCoin.map((item, index) => {
              return <tr key={index} >
                <td
                  style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined, color: item.futureTrading ? 'yellow' : 'white' }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td>
                <td style={{ color: item.priceChangePercent < 0 ? '#f44336' : '#26a69a', fontWeight: 'bold' }}>{item.priceChangePercent}% </td>
                <td>{item.assetName}</td>
                <td>{item.binanceTag ? item.binanceTag.join(' ') : ''} </td>
                <td
                  onClick={async () => {
                    setInfoSymbol(item.symbol);
                    setclickedDate(new Date());

                  }}
                >

                  <FontAwesomeIcon icon={faInfoCircle} />
                </td>
              </tr>;
            })}
          </tbody>
        </Table>

      </Container>
    </Fragment >
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(TopCoinTab);
