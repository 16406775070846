import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Alert, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

let timeout;
const ConfirmationModal = (props) => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        (() => {

            if (props.confirmationInfo.title) {
                handleShow();
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    handleClose();
                }, 5000);

            }
        })();
    }, [props.confirmationInfo]);

    return (
        <Fragment>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {props.confirmationInfo.title} </Modal.Title>
                </Modal.Header>
                <Modal.Body> {props.confirmationInfo.body}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ width: '15%', float: 'left' }} variant='primary' onClick={() => {
                        props.confirmationInfo.function(props.confirmationInfo.params);
                        handleClose();
                    }}>
                        Yes
                    </Button>
                    <Button style={{ width: '80%' }} variant='secondary' onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment >
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
