import React, { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import apis from '../../../apis';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

const BalanceButton = (props) => {
  useEffect(() => {
    (async () => {
      await refreshBalance();
      setInterval(async () => {
        await refreshBalance();
      }, CONFIG.REFRESH_INTERVAL.BALANCE);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await refreshBalance();
    })();
  }, [props.tradingType]);

  const refreshBalance = async () => {
    console.log('props.tradingType.refreshBalance', props.tradingType)
    props.setTradingBalance(await apis.dataSync('api_trading_get_balance', { trading_type: props.tradingType }));
  };

  return (
    <Fragment>
      <Button
        onClick={() => {
          refreshBalance();
        }}
        variant="warning"><u><b>{props.tradingBalance}</b></u> </Button>{' '}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceButton);
