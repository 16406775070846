import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/index';
import apis from '../../apis';
import { TradingViewEmbed, widgetType } from "react-tradingview-embed";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


import { Modal, Dropdown, Tabs, Alert, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../redux';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      margin: 4,
    }}
  />
);


function Chart(props) {
  const history = useHistory();
  if (!localStorage.getItem('token')) {
    history.push('/');
  }

  const [tradingViewAllFutureSymbol, setTradingViewAllFutureSymbol] = useState([]);

  let urlItem = window.location.href.split('/');
  let type = urlItem[urlItem.length - 1];

  useEffect(() => {
    (async () => {
      let allSymbols = await apis.dataSync('api_trading_get_all_symbols', { trading_type: type });
      allSymbols = allSymbols.slice(1, 150);

      setTradingViewAllFutureSymbol(
        <TradingViewEmbed
          widgetType={widgetType.ADVANCED_CHART}
          widgetConfig={{
            interval: "240",
            colorTheme: "dark",
            width: "100%",
            height: 960,
            symbol: "BINANCE:DOTUSDT",
            timezone: "Asia/Ho_Chi_Minh",
            watchlist: allSymbols.map(symbol => {
              return 'BINANCE:' + symbol;
            }),
          }}
        />
      );

    })();
  }, [props.tradingType]);

  return (
    <div>

      {/* <Button variant='secondary'><FontAwesomeIcon icon={faCogs} /></Button>{' '}
      <Button variant='primary'><FontAwesomeIcon icon={faChartBar} /></Button>{' '}
      <Button variant='primary'><FontAwesomeIcon icon={faExpand} /></Button>{' '}
      <Button variant='success'><FontAwesomeIcon icon={faSyncAlt} /></Button>{' '} */}


      <ColoredLine color='gray' />
      <Row>
        {tradingViewAllFutureSymbol}
      </Row>

    </div >
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Chart);
