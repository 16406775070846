

import CONFIG from './configs';
import axios from 'axios';
import { mapStateToProps, mapDispatchToProps } from './redux';
import store from './redux/store';
import { isValidTradingType } from './utils';
const aesjs = require('aes-js');
const apiEndPoint = CONFIG.API_ENDPOINT;


const dataSync = async (key, data = {}, alertMessage = false) => {
    if ("trading_type" in data && !isValidTradingType(data['trading_type'])) {
        return []
    }

    let body = {};
    body['date'] = new Date();
    body['key'] = key;
    body['data'] = data;

    let textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(body));
    let aesOfb = new aesjs.ModeOfOperation.ofb(aesjs.utils.utf8.toBytes(CONFIG.AES_SECRET), aesjs.utils.utf8.toBytes(CONFIG.AES_SECRET));
    let encryptedBytes = aesOfb.encrypt(textBytes);
    let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    let url = '/api/data';
    if (['api_login', 'api_signup'].includes(key)) {
        url = '/api/public';
    }

    return await axios.request({
        url: `${apiEndPoint + url}`,
        method: 'post',
        headers: {
            Key: key,
            Data: JSON.stringify(data),
            token: localStorage.getItem('token'),
            "Content-Type": "text/plain"
        },
        data: encryptedHex
    }).then((res) => {
        if (res.status === 200) {
            let response = decryptResponse(res.data);
            if (alertMessage) {
                showAlertMessage(response['message'], 'success');
            }
            return response['data'];
        }
    }).catch((error) => {
        if (error.response.status == 401) {
            localStorage.removeItem('token');
        }
        if (error.response.status !== 200) {
            if (error.response.data) {
                let response = decryptResponse(error.response.data);
                showAlertMessage(response['message'], 'warning');
            }
            return null;
        }
    });
};

const showAlertMessage = (message, type) => {
    store.dispatch({
        type: "ALERT",
        payload: ({
            date: new Date(),
            type: type,
            content: message,
        })
    });
};
const decryptResponse = (response) => {
    let encryptedBytes = aesjs.utils.hex.toBytes(response);
    let aesOfb = new aesjs.ModeOfOperation.ofb(aesjs.utils.utf8.toBytes(CONFIG.AES_SECRET), aesjs.utils.utf8.toBytes(CONFIG.AES_SECRET));
    let decryptedBytes = aesOfb.decrypt(encryptedBytes);
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

    let decryptedJson = JSON.parse(decryptedText);

    return decryptedJson;
};




const apis = {
    dataSync,
};

export default apis;