import React, { useState, useEffect } from 'react';
import CONFIG from '../../configs';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { connect } from 'react-redux';
import background from "../../assets/cover3.jpg";
import { Form, Navbar, Col, Row, Container, Nav, Button } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { SignUpForm, MainButton, NavigationBar } from '../../components/Home';
import { mapStateToProps, mapDispatchToProps } from '../../redux';
import apis from '../../apis';
var aesjs = require('aes-js');

const SignUp = (props) => {


  return (
    <div style={{
      backgroundImage: `url(${background})`,
      height: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'
    }}>

      <NavigationBar></NavigationBar>

      <SignUpForm></SignUpForm>


    </div>

  );
};


export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
