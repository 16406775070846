import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ReactJson from 'react-json-view';
import { mapStateToProps, mapDispatchToProps } from '../../redux';


const CoinInfoModal = (props) => {
    const [infoData, setInfoData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState('');
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    useEffect(() => {
        if (props.symbol) {
            (async () => {
                await getSymbolInfo();
            })();

            handleShow();
        }
    }, [props.symbol, props.date]);

    const getSymbolInfo = async () => {
        let data = await apis.dataSync('api_trading_get_coin_info', {
            trading_type: props.tradingType,
            'symbol': props.symbol,
        });
        setInfoData(data);
        setModalBody(bodyFormat(data));
    };

    const bodyFormat = (data) => {
        return <>
            <h2><img height={30} src={data['logo']}></img> {data['name']} [{data['symbol']}] </h2>
            <p>{data['description']} </p>
            <li>Website: <a target='_blank' href={data['urls']['website'][0]}>{data['urls']['website'][0]} </a></li>
            <li>Twitter: <a target='_blank' href={data['urls']['twitter'][0]}>{data['urls']['twitter'][0]} </a></li>
            <li>Chat: <a target='_blank' href={data['urls']['chat'][0]}>{data['urls']['chat'][0]} </a></li>
        </>;
    };

    return (
        <Fragment>
            <Modal show={showModal}
                size="lg"
                onHide={handleClose}>
                <Modal.Body
                    style={{
                        color: '#d8d8d8',
                        backgroundColor: '#131722'
                    }}
                >
                    {modalBody}
                    <hr></hr>
                    <ReactJson
                        src={infoData}
                        theme="monokai"
                        collapsed={true}
                        enableClipboard={false}
                        displayDataTypes={false}
                        indentWidth={2}
                        style={{ fontSize: '9.5pt' }}
                    />

                </Modal.Body>

                <Modal.Footer
                    style={{
                        color: '#d8d8d8',
                        backgroundColor: '#131722'
                    }}
                >
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

        </Fragment >
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(CoinInfoModal);
