import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import CONFIG from '../../../configs/index';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

const LeverageUpdate = (props) => {
  const [leverage, setLeverage] = useState(CONFIG.LEVERAGE.DEFAULT);

  useEffect(() => {
  }, []);

  const changeLeverage = async () => {

    if (!props.futureSymbol) {
      return;
    }
    let response = await apis.dataSync('api_future_update_leverage', {
      'order_symbol': props.futureSymbol,
      'order_leverage': leverage,
    });

    if (response) {
      props.setAlert({
        date: new Date(),
        type: 'success',
        content: `Update symbol ${response['symbol']} leverage ${response['leverage']}!`,
      });
    }
  };

  return (
    <Fragment>
      <ButtonGroup style={{ width: '100%' }} >
        <DropdownButton size='sm' variant='outline-light' as={ButtonGroup} title={leverage + 'x'} id='bg-vertical-dropdown-1'>
          {CONFIG.LEVERAGE.LIST.map((item, idx) => {
            return <Dropdown.Item
              key={idx}
              size='sm'
              onClick={() => {
                setLeverage(item);
              }}
              variant='outline-light'
              value={item}
              active={leverage === item}
            >
              {item}
            </Dropdown.Item>;
          })}

        </DropdownButton>
        <Button variant='secondary' size='sm'
          onClick={() => {
            changeLeverage();
          }}
        >UPDATE</Button>{' '}
      </ButtonGroup>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LeverageUpdate);
