import React, { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import apis from '../../../apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { useHistory } from 'react-router-dom';

const TabButton = (props) => {
  const history = useHistory();

  const toggleTab = async () => {
    let tradingType = localStorage.getItem('trading_type');
    let platformArray = ['FUTURE', 'SPOT'];
    let index = platformArray.indexOf(tradingType);
    let nextTab;

    if (index >= 0 && index < platformArray.length - 1)
      nextTab = platformArray[index + 1];
    else
      nextTab = platformArray[0];

    localStorage.setItem('trading_type', nextTab);
    props.setTradingType(nextTab.toUpperCase());
    props.setFutureSymbolList([])
  };

  return (
    <Fragment>
      <Button
        style={{ width: '120px' }}
        onClick={() => {
          toggleTab();
        }}
        variant='success'><b>{props.tradingType.toUpperCase()}</b> </Button>{' '}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TabButton);
