import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { changeSymbol } from '../../../utils';


const NotificationTab = (props) => {
  useEffect(() => {
    (async () => {
      await refreshNotification();
    })();

    setInterval(async () => {
      await refreshNotification();
    }, CONFIG.REFRESH_INTERVAL.NOTIFICATION);
  }, []);

  const delAlerts = async (id) => {
    await apis.dataSync('api_future_delete_alert', {
      'alert_id': id,
    });
    await refreshNotification();
  };
  const replaceSymptoms = (t) => {
    if (!t) {
      return '';
    }
    return t.replace('TRIGGERED', 'TRIG');
  };
  const refreshNotification = async () => {
    props.setNotification(await apis.dataSync('api_trading_get_alerts'));
  };

  return (
    <Fragment>
      <Container>
        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th
                style={{ cursor: 'default' }}
                onClick={() => {
                  refreshNotification();
                }}
              >NOTI</th>
              <th>PRICE</th>
              <th>STAS</th>
              <th>UPDATE</th>
              <th
                style={{ textAlign: 'center' }}
              >X</th>
            </tr>
          </thead>
          <tbody>
            {props.notification.map((item) => {
              return <tr key={item.id} >
                <td
                  style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td>
                <td>{item.triggered_price}</td>
                <td style={{ color: item.status === 'TRIGGERED' ? '#f44336' : 'white' }}><b>{replaceSymptoms(item.status)}</b></td>
                <td>{item.last_update} </td>
                <td
                  style={{ textAlign: 'center' }}
                  onClick={(e) => {
                    delAlerts(item.id);
                  }}
                ><FontAwesomeIcon icon={faWindowClose} /></td>
              </tr>;
            })}
          </tbody>
        </Table>

      </Container>
    </Fragment >
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTab);
