import { createStore } from "redux";
const initState = {
    isloggedIn: false,
    tradingType: '',
    tradingBalance: 0.0,
    futureSymbol: null,
    futureOrderPrice: '',
    stopLossPrice: '',
    takeProfitPrice: '',
    topCoin: [],
    futureTrades: [],
    allOders: [],
    allSymbolList: [],
    futureSymbolList: [],
    symbolListPrice: {},
    amountPercentage: 0,
    alert: {
        type: 'success',
        date: new Date(),
        content: '',
    },
    notification: [],
};
const reducer = (state = initState, action) => {
    switch (action.type) {
        case "IS_LOGGED_IN":
            return {
                ...state,
                isloggedIn: action.payload
            };
        case "TRADING_TYPE":
            return {
                ...state,
                tradingType: action.payload
            };
        case "TRADING_BALANCE":
            return {
                ...state,
                tradingBalance: action.payload
            };

        case "FUTURE_SYMBOL":
            return {
                ...state,
                futureSymbol: action.payload
            };
        case "SYMBOL_LIST":
            return {
                ...state,
                futureSymbolList: action.payload
            };
        case "ALL_SYMBOL_LIST":
            return {
                ...state,
                allSymbolList: action.payload
            };
        case "FUTURE_ORDER_PRICE":
            return {
                ...state,
                futureOrderPrice: action.payload
            };
        case "STOPLOSS_PRICE":
            return {
                ...state,
                stopLossPrice: action.payload
            };
        case "TAKEPROFIT_PRICE":
            return {
                ...state,
                takeProfitPrice: action.payload
            };

        case "FUTURE_TRADES":
            return {
                ...state,
                futureTrades: action.payload
            };

        case "ALL_ORDERS":
            return {
                ...state,
                allOders: action.payload
            };

        case "SYMBOL_LIST_PRICE":
            return {
                ...state,
                symbolListPrice: action.payload
            };
        case "AMOUNT_PERCENTAGE":
            return {
                ...state,
                amountPercentage: action.payload
            };

        case "ALERT":
            return {
                ...state,
                alert: action.payload
            };
        case "NOTIFICATION":
            return {
                ...state,
                notification: action.payload
            };
        case "TOP_COIN":
            return {
                ...state,
                topCoin: action.payload
            };
        default:
            return state;
    }
};

const store = createStore(reducer);
export default store;