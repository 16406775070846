import apis from './apis';
import CONFIG from './configs/index';

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

const changeSymbol = async (props, symbol) => {
    if (!props.allSymbolList.includes(symbol)) {
        navigator.clipboard.writeText(symbol);
        return;
    }
    let symbolListCopy = [...props.futureSymbolList];
    if (!props.futureSymbolList.includes(symbol)) {

        if (symbolListCopy.length >= CONFIG.MAX_SYMBOL_IN_LIST) {
            let removeItem = symbolListCopy[Math.floor(Math.random() * symbolListCopy.length)];

            symbolListCopy = symbolListCopy.filter(i => i !== removeItem).sort();
        }
        symbolListCopy.push(symbol);
        props.setFutureSymbolList(symbolListCopy.sort());
    }

    props.setFutureSymbol(symbol);
    props.setFutureOrderPrice('');
    props.setStopLossPrice('');
    props.setTakeProfitPrice('');

    if (!arraysEqual(symbolListCopy, props.futureSymbolList) && this.isValidTradingType(props.tradingType)) {
        props.setSymbolListPrice(await apis.dataSync('api_trading_update_symbols', { trading_type: props.tradingType, 'symbols': symbolListCopy }));
    }
    navigator.clipboard.writeText(symbol);
    localStorage.setItem('symbol', symbol);
};

const isValidTradingType = (tradingType) => {
    if (!tradingType || (!['FUTURE', 'SPOT'].includes(tradingType))) {
        return false
    }
    return true;
};

export { changeSymbol, isValidTradingType };