import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ReactJson from 'react-json-view';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';


const LogInfoModal = (props) => {
    const [tradeData, setTradeData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [modalTitle, setModalTitle] = useState('');
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    useEffect(() => {
        if (props.id) {
            (async () => {
                await getTradeLog();
            })();

            handleShow();
        }

    }, [props.id, props.date]);

    const getTradeLog = async () => {
        setTradeData(await apis.dataSync('api_trading_get_trading_logs', {
            trading_type: props.tradingType,
            'id': props.id,
        }));
    };

    return (
        <Fragment>

            <Modal show={showModal}
                size="lg"
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant='success' onClick={getTradeLog}>
                        Refresh
                    </Button>
                    <hr></hr>
                    <ReactJson
                        src={tradeData}
                        theme="monokai"
                        enableClipboard={false}
                        displayDataTypes={false}
                        indentWidth={2}
                        style={{ fontSize: '9.5pt' }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

        </Fragment >
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(LogInfoModal);
