import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CONFIG from '../../configs/index';
import apis from '../../apis';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TabButton, BalanceButton, SystemInfo, InputPrice, RefreshButton, ChartButton, SymbolSearch, SymbolList, AlertComponent, LeverageUpdate, AmountPercentage, TopCoinTab, PositionTab, TradingTab, TradeButton, NotificationTab, HistoryTab } from '../../components/Common';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Alert, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../redux';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      margin: 4,
    }}
  />
);

function Future(props) {
  const history = useHistory();
  if (!localStorage.getItem('token')) {
    history.push('/');
  }

  useEffect(() => {
    let tradingType = localStorage.getItem('trading_type');

    if (!tradingType || (!['FUTURE', 'SPOT'].includes(tradingType))) {
      tradingType = 'spot';
    }

    localStorage.setItem('trading_type', tradingType);
    props.setTradingType(tradingType.toUpperCase());

  }, []);

  return (
    // <div style={{ backgroundImage: `url(${background})` }}>
    <div>

      <Container fluid='sm'>
        <ColoredLine color='gray' />
        <Row>
          <Container >

            <TabButton></TabButton>
            <RefreshButton></RefreshButton>
            <BalanceButton ></BalanceButton>
            {/* <Button variant='secondary'><FontAwesomeIcon icon={faCogs} /></Button>{' '} */}

            <ChartButton ></ChartButton>
            {/* <Button variant='primary'><FontAwesomeIcon icon={faExpand} /></Button>{' '}
            <Button variant='success'><FontAwesomeIcon icon={faSyncAlt} /></Button>{' '} */}

          </Container>
        </Row>
        <ColoredLine color='gray' />
        <Row>
          <Col className='col-7'>
            <SymbolSearch></SymbolSearch>
            <SymbolList></SymbolList>
          </Col>
          <Col className='col-5' >
            {
              (props.tradingType == 'FUTURE') ? <><LeverageUpdate></LeverageUpdate><ColoredLine color='gray' /></> : <></>
            }
            <AmountPercentage></AmountPercentage>

            <ColoredLine color='gray' />
            <InputPrice></InputPrice>

          </Col>
        </Row>

        <ColoredLine color='gray' />
        <Row>
          <TradeButton></TradeButton>
        </Row>

        <ColoredLine color='gray' />
        <Row>
          <Container >
            <Tabs
              style={{ marginTop: '10px' }}
              defaultActiveKey="position"
              id="controlled-tab-example"
              className="mb-3" >
              <Tab eventKey="position" title="POSITIONS" >
                <Container style={{ maxHeight: '600px', overflow: 'scroll' }}>
                  <PositionTab ></PositionTab>
                </Container>
              </Tab>
              <Tab eventKey="trade-tab" title="TRADING">
                {/* <Container style={{ maxHeight: '600px', overflow: 'auto' }} id='trade-tab'> */}
                <TradingTab></TradingTab>
                {/* </Container> */}
              </Tab>
              <Tab eventKey="history" title="HISTORY">
                <Container style={{ height: '600px', overflow: 'scroll' }}>
                  <HistoryTab></HistoryTab>
                </Container>
              </Tab>
              <Tab eventKey="notification" title="NOTIFY">
                <Container style={{ maxHeight: '600px', overflow: 'scroll' }}>
                  <NotificationTab></NotificationTab>
                </Container>
              </Tab>
              <Tab eventKey="topcoin" title="TOPCOIN">
                <Container style={{ maxHeight: '600px', overflow: 'scroll' }}>
                  <TopCoinTab></TopCoinTab>
                </Container>
              </Tab>
            </Tabs>

          </Container>

        </Row>
        <ColoredLine color='gray' />
        <br></br>
        <ColoredLine color='gray' />

        <AlertComponent alert={props.alert}></AlertComponent>
      </Container>
      <SystemInfo />

    </div >
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Future);
