import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import { useHistory } from "react-router-dom";
import apis from '../../../apis';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { Form, Modal, Navbar, Alert, Col, Row, Container, Nav, Button } from 'react-bootstrap';

let alertTimeout;
const SignUpForm = (props) => {
    const history = useHistory();

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        repassword: '',
    });
    const [alertVisibility, setAlertVisibility] = useState('hidden');
    const [alertContent, setAlertContent] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await apis.dataSync('api_signup', {
            email: inputs.email,
            password: inputs.password,
            repassword: inputs.repassword,
        });

        if (response) {
            setInputs({
                email: '',
                password: '',
                repassword: '',
            });
            localStorage.setItem('email', inputs.email);
            localStorage.setItem('password', '');
            handleShow();
        }
    };

    useEffect(() => {
        showAlert(props.alert['content']);
    }, [props.alert]);

    const showAlert = (message) => {
        setAlertVisibility('visible');
        clearTimeout(alertTimeout);
        setAlertContent(message);
        alertTimeout = setTimeout(() => {
            setAlertVisibility('hidden');
        }, 2000);
    };

    return (
        <Fragment>
            <Container fluid="sm" style={{ marginTop: '20vh' }}>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>SIGNUP SUCCESSFULLY</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please checkout your email inbox to verify the email</Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={() => {
                            history.push("/");
                        }}>
                            Login
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className=" d-flex align-items-center justify-content-center" >
                    <Form style={{ width: '400px' }}>
                        <Form.Label style={{ color: 'white' }}><h1>SIGNUP</h1></Form.Label>
                        <h5 style={{ color: 'white' }}>Welcome to Autobot</h5>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                name='email'
                                size='lg'
                                value={inputs.email}
                                onChange={handleChange.bind(this)}
                            />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name='password'
                                size='lg'
                                value={inputs.password}
                                onChange={handleChange.bind(this)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                            <Form.Control
                                type="password"
                                placeholder="Re-Password"
                                name='repassword'
                                size='lg'
                                value={inputs.repassword}
                                onChange={handleChange.bind(this)}
                            />
                        </Form.Group>
                        <hr ></hr>

                        <div className="d-grid gap-2">
                            <Button variant="secondary" size='lg' type="submit" onClick={handleSubmit}>
                                SIGNUP
                            </Button>
                        </div>
                        <Form.Label style={{ color: 'red', fontSize: '12pt', visibility: alertVisibility }} ><p>{alertContent}</p></Form.Label>


                        <hr></hr>
                        <Form.Label style={{ color: 'white', fontSize: '12pt' }} onClick={() => {
                            history.push("/");
                        }}><p>Already a member? Log In</p></Form.Label>


                    </Form>
                </div>

            </Container>
        </Fragment>
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
