import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { changeSymbol } from '../../../utils';
import { ConfirmationModal } from '../index';

const PositionTab = (props) => {
  const [closePrice, setClosePrice] = useState({});
  const [positions, setPositions] = useState([]);
  const [allOders, setAllOrders] = useState([]);

  const [totalSymbols, setTotalSymbols] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const [totalEarnedUsdt, setTotalEarnedUsdt] = useState(0);
  const [totalProfitUsdt, setTotalProfitUsdt] = useState(0);
  const [totalLossUsdt, setTotalLossUsdt] = useState(0);

  const [confirmationInfo, setConfirmationInfo] = useState({
    title: null,
    body: null,
    function: function init() {
    },
    params: {},
  });

  useEffect(() => {
    (async () => {
      setInterval(async () => {
        await Promise.all([
          (async () => {
            await refreshPositions();
          })()
        ]);
      }, CONFIG.REFRESH_INTERVAL.POSITION);

      setInterval(async () => {
        await Promise.all([
          (async () => {
            await refreshOrders();
          })()
        ]);
      }, CONFIG.REFRESH_INTERVAL.ORDER);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await refreshPositions();
      await refreshOrders();
    })();
  }, [props.tradingType]);

  useEffect(() => {
    setTotalSymbols(positions.length);
    setTotalSize(positions.reduce((partial, item) => partial + item.positionSize, 0).toFixed(0));
    let profit = positions.reduce((partial, item) => partial + ((item.profitUSDT >= 0) ? item.profitUSDT : 0), 0).toFixed(2);
    let loss = positions.reduce((partial, item) => partial + ((item.profitUSDT < 0) ? item.profitUSDT : 0), 0).toFixed(2);
    let earned = (parseFloat(profit) + parseFloat(loss)).toFixed(2);

    setTotalProfitUsdt(profit);
    setTotalLossUsdt(loss);
    setTotalEarnedUsdt(earned);
  }, [positions]);

  const cancelOrder = async (symbol, orderId) => {
    await apis.dataSync('api_trading_close_order', {
      trading_type: props.tradingType,
      'order_symbol': symbol,
      'order_id': orderId,
    });

    setTimeout(async () => {
      await refreshOrders();
    }, 2000);
  };

  const refreshOrders = async () => {
    let data = await apis.dataSync('api_trading_get_orders', { trading_type: props.tradingType });
    if (data) {
      setAllOrders(data);
    }

  };

  const refreshPositions = async () => {
    let data = await apis.dataSync('api_trading_get_positions', { trading_type: props.tradingType });
    if (data) {
      setPositions(data);
    }
  };


  const closePosition = async (symbolInfo) => {
    let orderQuantity = 0;
    let orderPrice = 0;

    positions.forEach((item) => {
      if (symbolInfo.symbol === item['symbol'] && symbolInfo.positionSide === item['positionSide']) {
        orderQuantity = Math.abs(parseFloat(item['positionAmt']));
      }
    });

    let data = {
      trading_type: props.tradingType,
      'position_side': symbolInfo.positionSide,
      'order_symbol': symbolInfo.symbol,
      'order_quantity': orderQuantity,
      'order_price': Math.abs(parseFloat(orderPrice)),
      'entry_price': Math.abs(parseFloat(symbolInfo.entryPrice))
    };

    await apis.dataSync('api_trading_close_positions', data);

    setTimeout(async () => {
      await refreshPositions();
    }, 1000);
  };

  return (
    <Fragment>
      <ConfirmationModal confirmationInfo={confirmationInfo}></ConfirmationModal>
      <Container>
        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th>SYMBOLS</th>
              <th>SIZE</th>
              <th>PROFIT / LOSS</th>
            </tr>

            <tr>
              <td>{totalSymbols}</td>
              <td>{totalSize} $/ {props.tradingBalance > 0 ? (totalSize * 100 / props.tradingBalance).toFixed(2) : 0} %</td>
              <td ><b><span style={{ color: totalEarnedUsdt >= 0 ? '#26a69a' : '#f44336' }}>{totalEarnedUsdt + ' $'}</span> [ <span style={{ color: '#26a69a' }}>{totalProfitUsdt + ' $'}</span> / <span style={{ color: '#f44336' }}>{totalLossUsdt + ' $'}</span> ]</b></td>
            </tr>

          </thead>

        </Table>
        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th
                style={{ cursor: 'default' }}
                onClick={() => {
                  refreshPositions();
                }}
              ><span style={{ color: '#fcba03' }}>{(props.tradingType == 'FUTURE') ? 'POSITIONS' : 'ASSETS'}</span></th>
              <th>ENTRY</th>
              <th>SIZE [U]</th>
              <th>PROFIT</th>
              <th
                style={{ textAlign: 'center' }}
              >X</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((item) => {
              return <tr key={item.symbol + item.positionSide} >
                <td style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined, color: item.positionSide === 'LONG' ? '#26a69a' : '#f44336' }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td>
                <td>{item.entryPrice}</td>
                <td>{item.positionSize}</td>
                <td style={{ color: item.profitPercentage >= 0 ? '#26a69a' : '#f44336' }}><b>{item.profitUSDT} [{item.profitPercentage}%]</b></td>
                <td
                  onClick={(e) => {
                    setConfirmationInfo({
                      title: `ARE YOU SURE?`,
                      body: <p>CLOSE <b>{item.symbol}-{item.positionSide}</b>: <span style={{ color: item.profitPercentage >= 0 ? '#26a69a' : '#f44336' }}><b><u>{item.profitUSDT}</u></b></span>  [USDT]</p>,
                      function: closePosition,
                      params: item,
                    });

                  }}
                  style={{ textAlign: 'center' }}
                ><FontAwesomeIcon icon={faWindowClose} /></td>
              </tr>;
            })}
          </tbody>
        </Table>

        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th style={{ cursor: 'default' }}
                onClick={() => {
                  refreshOrders();
                }}
              >OPEN ORDERS</th>
              <th>PRICE</th>
              <th>EXEC</th>
              <th>QTY</th>
              <th>SIZE</th>
              <th
                style={{ textAlign: 'center' }}
              >X</th>
            </tr>
          </thead>
          <tbody>
            {allOders.map((item) => {
              return (item.isOpenOrder == true) ? <tr key={item.orderId} >
                <td style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined, color: item.positionSide === 'LONG' ? '#26a69a' : '#f44336' }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td>
                <td>{item.price + item.stopPrice}</td>
                <td style={{ color: item.priceDistance < 5 ? '#f44336' : 'none' }}>{item.priceDistance} %</td>
                <td>{item.origQty}</td>
                <td>{(item.price * item.origQty).toFixed(2)}</td>
                <td
                  onClick={(e) => {
                    cancelOrder(item.symbol, item.orderId);
                  }}
                  style={{ textAlign: 'center' }}
                ><FontAwesomeIcon icon={faWindowClose} /></td>
              </tr> : null;
            })}
          </tbody>
        </Table>
        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th>CLOSE ORDERS</th>
              <th>PRICE</th>
              <th>EXEC</th>
              <th>QTY</th>
              <th>SIZE</th>
              <th
                style={{ textAlign: 'center' }}
              >X</th>
            </tr>
          </thead>
          <tbody>
            {allOders.map((item) => {
              return (item.isOpenOrder == false) ? <tr key={item.orderId} >
                <td style={{ cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined, color: item.positionSide === 'LONG' ? '#26a69a' : '#f44336' }}
                  onClick={async () => {
                    await changeSymbol(props, item.symbol);
                  }}
                ><b>{item.symbol}</b></td>
                <td>{item.price + item.stopPrice}</td>
                <td>{item.priceDistance} %</td>
                <td>{item.origQty}</td>
                <td>{(item.price * item.origQty).toFixed(2)}</td>
                <td
                  onClick={(e) => {
                    cancelOrder(item.symbol, item.orderId);
                  }}
                  style={{ textAlign: 'center' }}
                ><FontAwesomeIcon icon={faWindowClose} /></td>
              </tr> : null;
            })}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(PositionTab);
