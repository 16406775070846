import React, { useState, useEffect } from 'react';
import CONFIG from '../../configs';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { connect } from 'react-redux';
import background from "../../assets/cover3.jpg";
import { Form, Navbar, Col, Row, Container, Nav, Button } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { LoginForm, MainButton, NavigationBar } from '../../components/Home';
import { mapStateToProps, mapDispatchToProps } from '../../redux';
import apis from '../../apis';
var aesjs = require('aes-js');

const Home = (props) => {

  useEffect(() => {

    if (localStorage.getItem('token')) {
      (async () => {
        const response = await apis.dataSync('api_get_user');

        if (response) {
          props.setIsloggedIn(true);
        } else {
          props.setIsloggedIn(false);
        }
      })();

    } else {
      props.setIsloggedIn(false);
    }

  }, []);

  return (
    <div style={{
      backgroundImage: `url(${background})`,
      height: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'
    }}>

      <NavigationBar></NavigationBar>

      {!props.isloggedIn ?
        <LoginForm></LoginForm>
        :
        <MainButton></MainButton>
      }
    </div>

  );
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);
