import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CONFIG from '../../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
let symbolListPriceInterval;

const InputPrice = (props) => {
  useEffect(() => {
    (async () => {
      props.setSymbolListPrice(await apis.dataSync('api_trading_get_symbols_price', { trading_type: props.tradingType }));
    })();
  }, [props.tradingType]);

  useEffect(() => {
    (async () => {
      clearInterval(symbolListPriceInterval);

      if (props.futureSymbol) {
        symbolListPriceInterval = setInterval(async () => {
          props.setSymbolListPrice(await apis.dataSync('api_trading_get_symbols_price', { trading_type: props.tradingType }));
        }, CONFIG.REFRESH_INTERVAL.SYMBOL_PRICE);
      }
    })();

  }, [props.futureSymbol, props.tradingType]);

  const getDecimalNum = (value) => {
    if (value === 0) {
      return 1;
    }
    let decimal = value.toString().split('.')[1];
    if (!decimal) {
      return 0;
    }
    return decimal.length;
  };

  const toFixedNumber = (num, digits, base) => {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  };

  const updateInputPrice = (e, price, setPrice) => {
    console.log('props.symbolListPrice', props.symbolListPrice)
    if (!props.futureSymbol) {
      e.target.value = null;
      return;
    }

    let newPrice;
    switch (true) {
      case (e._reactName === 'onClick' && !e.target.value) || (e._reactName === 'onClick' && e.target.value && !price):
        if (props.symbolListPrice.length === 0 || !props.symbolListPrice.hasOwnProperty(props.futureSymbol)) {
          return;
        }
        newPrice = props.symbolListPrice[props.futureSymbol];
        setPrice(newPrice);
        break;

      case (e._reactName === 'onWheel' && e.target.value && price > 0 && (e.deltaY > 0 || e.deltaY < 0)):
        let decimal = getDecimalNum(price);
        while ((price * 0.001).toFixed(decimal) == 0) {
          decimal = decimal + 1;
        }
        if (e.deltaY < 0) {
          newPrice = toFixedNumber(price * 1.001, decimal);
        } else if (e.deltaY > 0) {
          newPrice = toFixedNumber(price * 0.999, decimal);
        }
        setPrice(newPrice);
        break;

      case (e._reactName === 'onInput' && e.nativeEvent.detail === undefined):
        e.target.value = price;
        break;

      case (e._reactName === 'onInput' && e.nativeEvent.detail === 0):
        setPrice(parseFloat(e.target.value));
        break;
      default:
        return;
    }
  };
  return (
    <Fragment>

      <InputGroup size='sm' className='mb-2'>
        <FormControl
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
          type='number'
          step='any'
          placeholder='ORDER PRICE'
          onMouseEnter={(e) => {
            disableBodyScroll(document.querySelector('body'));
          }}
          onMouseLeave={(e) => {
            enableBodyScroll(document.querySelector('body'));
          }}
          onClick={(e) => {
            updateInputPrice(e, props.futureOrderPrice, props.setFutureOrderPrice);
          }}
          onInput={(e) => {
            updateInputPrice(e, props.futureOrderPrice, props.setFutureOrderPrice);
          }}
          onWheel={(e) => {
            updateInputPrice(e, props.futureOrderPrice, props.setFutureOrderPrice);
          }}
          value={props.futureOrderPrice}
        />
        <Button
          variant='secondary'
          onClick={(e) => {
            props.setFutureOrderPrice('');
          }}
        ><FontAwesomeIcon icon={faEraser} /></Button>{' '}
      </InputGroup>

      <InputGroup size='sm' className='mb-2'>
        <FormControl
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
          type='number'
          step='any'
          placeholder='STOP LOSS'
          onMouseEnter={(e) => {
            disableBodyScroll(document.querySelector('body'));
          }}
          onMouseLeave={(e) => {
            enableBodyScroll(document.querySelector('body'));
          }}
          onClick={(e) => {
            updateInputPrice(e, props.stopLossPrice, props.setStopLossPrice);
          }}
          onInput={(e) => {
            updateInputPrice(e, props.stopLossPrice, props.setStopLossPrice);
          }}
          onWheel={(e) => {
            updateInputPrice(e, props.stopLossPrice, props.setStopLossPrice);
          }}
          value={props.stopLossPrice}
        />
        <Button
          variant='secondary'
          onClick={(e) => {
            props.setStopLossPrice('');
          }}
        ><FontAwesomeIcon icon={faEraser} /></Button>{' '}
      </InputGroup>

      <InputGroup size='sm' className='mb-2'>
        <FormControl
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
          type='number'
          step='any'
          placeholder='TAKE PROFIT'
          onMouseEnter={(e) => {
            disableBodyScroll(document.querySelector('body'));
          }}
          onMouseLeave={(e) => {
            enableBodyScroll(document.querySelector('body'));
          }}
          onClick={(e) => {
            updateInputPrice(e, props.takeProfitPrice, props.setTakeProfitPrice);
          }}
          onInput={(e) => {
            updateInputPrice(e, props.takeProfitPrice, props.setTakeProfitPrice);
          }}
          onWheel={(e) => {
            updateInputPrice(e, props.takeProfitPrice, props.setTakeProfitPrice);
          }}
          value={props.takeProfitPrice}
        />
        <Button
          variant='secondary'
          onClick={(e) => {
            props.setTakeProfitPrice('');
          }}
        ><FontAwesomeIcon icon={faEraser} /></Button>{' '}
      </InputGroup>

    </Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(InputPrice);
