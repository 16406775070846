import React, { Fragment, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CONFIG from '../../../configs/index';
import apis from '../../../apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import { useHistory } from 'react-router-dom';

const RefreshButton = (props) => {
  const history = useHistory();

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <Fragment>
      <Button
        variant='primary'
        onClick={() => {
          refreshPage();
        }}
      ><FontAwesomeIcon icon={faSyncAlt} /></Button>{' '}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton);
