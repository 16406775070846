import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import apis from '../../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { LogInfoModal } from '../index';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';
import CONFIG from '../../../configs/index';

const formatDate = (date) => {
  return `${String(date.getFullYear()).padStart(4, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const HistoryTab = (props) => {
  const [historyTrades, setHistoryTrades] = useState([]);
  const [historyDaysList, setHistoryDaysList] = useState([]);
  const [selectedDay, setSelectedDay] = useState(formatDate(new Date()));
  const [clickedTradeId, setClickedTradeId] = useState('');
  const [clickedDate, setclickedDate] = useState(new Date());

  const [totalEarnedUsdt, setTotalEarnedUsdt] = useState(0);
  const [totalProfitUsdt, setTotalProfitUsdt] = useState(0);
  const [totalLossUsdt, setTotalLossUsdt] = useState(0);

  useEffect(() => {
    (async () => {
      await getHistoryTrades(selectedDay);
    })();
    setInterval(async () => {
      await getHistoryTrades(selectedDay);
    }, CONFIG.REFRESH_INTERVAL.HISTORY);

    setHistoryDaysList(getHistoryDaysList());



    /* Chart code */
    let data = [{
      "date": "2012-01-01",
      "distance": 227,
      "townName": "New York",
      "townSize": 12,
      "latitude": 40.71,
      "duration": 408
    }, {
      "date": "2012-01-02",
      "distance": 371,
      "townName": "Washington",
      "townSize": 7,
      "latitude": 38.89,
      "duration": 482
    }, {
      "date": "2012-01-03",
      "distance": 433,
      "townName": "Wilmington",
      "townSize": 3,
      "latitude": 34.22,
      "duration": 562
    }, {
      "date": "2012-01-04",
      "distance": 345,
      "townName": "Jacksonville",
      "townSize": 3.5,
      "latitude": 30.35,
      "duration": 379
    }, {
      "date": "2012-01-05",
      "distance": 480,
      "townName": "Miami",
      "townSize": 5,
      "latitude": 25.83,
      "duration": 501
    }, {
      "date": "2012-01-06",
      "distance": 386,
      "townName": "Tallahassee",
      "townSize": 3.5,
      "latitude": 30.46,
      "duration": 443
    }, {
      "date": "2012-01-07",
      "distance": 348,
      "townName": "New Orleans",
      "townSize": 5,
      "latitude": 29.94,
      "duration": 405
    }, {
      "date": "2012-01-08",
      "distance": 238,
      "townName": "Houston",
      "townSize": 8,
      "latitude": 29.76,
      "duration": 309
    }, {
      "date": "2012-01-09",
      "distance": 218,
      "townName": "Dalas",
      "townSize": 8,
      "latitude": 32.8,
      "duration": 287
    }, {
      "date": "2012-01-10",
      "distance": 349,
      "townName": "Oklahoma City",
      "townSize": 5,
      "latitude": 35.49,
      "duration": 485
    }, {
      "date": "2012-01-11",
      "distance": 603,
      "townName": "Kansas City",
      "townSize": 5,
      "latitude": 39.1,
      "duration": 890
    }, {
      "date": "2012-01-12",
      "distance": 534,
      "townName": "Denver",
      "townSize": 9,
      "latitude": 39.74,
      "duration": 810
    }, {
      "date": "2012-01-13",
      "townName": "Salt Lake City",
      "townSize": 6,
      "distance": -125,
      "duration": 670,
      "latitude": 40.75,
      "dashLength": 8,
      "alpha": 0.4
    }, {
      "date": "2012-01-14",
      "latitude": 36.1,
      "duration": 470,
      "townName": "Las Vegas"
    }, {
      "date": "2012-01-15"
    }, {
      "date": "2012-01-16"
    }, {
      "date": "2012-01-17"
    }];

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    chart.zoomOutButton.set("forceHidden", true);

    chart.get("colors").set("step", 2);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
      tooltip: am5.Tooltip.new(root, {})
    }));


    let distanceAxisRenderer = am5xy.AxisRendererY.new(root, {});
    distanceAxisRenderer.grid.template.set("forceHidden", true);
    let distanceAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: distanceAxisRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    let latitudeAxisRenderer = am5xy.AxisRendererY.new(root, {});
    latitudeAxisRenderer.grid.template.set("forceHidden", true);
    let latitudeAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: latitudeAxisRenderer,
      forceHidden: true
    }));

    let durationAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true
    });
    durationAxisRenderer.grid.template.set("forceHidden", true);
    let durationAxis = chart.yAxes.push(am5xy.DurationAxis.new(root, {
      baseUnit: "minute",
      renderer: durationAxisRenderer,
      extraMax: 0.3
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let distanceSeries = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: distanceAxis,
      valueYField: "distance",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY} miles"
      })
    }));

    distanceSeries.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd"
    });

    let latitudeSeries = chart.series.push(am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: latitudeAxis,
      valueYField: "latitude",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        labelText: "latitude: {valueY} ({townName})"
      })
    }));

    latitudeSeries.strokes.template.setAll({ strokeWidth: 2 });

    // Add circle bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    latitudeSeries.bullets.push(function () {
      let graphics = am5.Circle.new(root, {
        strokeWidth: 2,
        radius: 5,
        stroke: latitudeSeries.get("stroke"),
        fill: root.interfaceColors.get("background"),
      });

      graphics.adapters.add("radius", function (radius, target) {
        return target.dataItem.dataContext.townSize;
      })

      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      yAxis: distanceAxis
    }));


    distanceSeries.data.setAll(data);
    latitudeSeries.data.setAll(data);
    xAxis.data.setAll(data);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    distanceSeries.appear(1000);
    chart.appear(1000, 100);


  }, []);


  useEffect(() => {
    (async () => {
      await getHistoryTrades(selectedDay);
    })();
  }, [props.tradingType]);


  const getHistoryDaysList = () => {
    let dayList = [];
    for (let i = 0; i < 30; i++) {
      let d = new Date();
      d.setDate(d.getDate() - i);
      dayList.push(formatDate(d));
    }
    return dayList;
  };

  const getHistoryTrades = async (day) => {
    let history = await apis.dataSync('api_trading_get_history_trades', {
      trading_type: props.tradingType,
      'day': day,
    });

    setHistoryTrades(history);
    let profit = history.reduce((partial, item) => partial + ((item.trade_profit >= 0) ? item.trade_profit : 0), 0).toFixed(2);
    let loss = history.reduce((partial, item) => partial + ((item.trade_profit < 0) ? item.trade_profit : 0), 0).toFixed(2);
    let earned = (parseFloat(profit) + parseFloat(loss)).toFixed(2);
    setTotalProfitUsdt(profit);
    setTotalLossUsdt(loss);
    setTotalEarnedUsdt(earned);
  };


  return (
    <Fragment>
      <div id="chartdiv" style={{
        width: "100%",
        height: "400px",
        border: '1px solid gray',
      }}></div>

      <LogInfoModal id={clickedTradeId} date={clickedDate}></LogInfoModal>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="dark">
          {selectedDay}
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark">
          {historyDaysList.map((item) => {
            return <Dropdown.Item
              key={`history-item-${item}`}
              onClick={async () => {
                setSelectedDay(item);
                await getHistoryTrades(item);
              }}
            >{item}</Dropdown.Item>;
          })}
        </Dropdown.Menu>
      </Dropdown>

      <Container>

        <Table bordered hover variant='dark' size='sm' >
          <thead>
            <tr>
              <th>PROFIT / LOSS</th>
            </tr>

            <tr>
              <td ><b><span style={{ color: totalEarnedUsdt >= 0 ? '#26a69a' : '#f44336' }}>{totalEarnedUsdt + ' $'}</span> [ <span style={{ color: '#26a69a' }}>{totalProfitUsdt + ' $'}</span> / <span style={{ color: '#f44336' }}>{totalLossUsdt + ' $'}</span> ]</b></td>
            </tr>
          </thead>

        </Table>
        <Table bordered hover variant='dark' size='sm' style={{ overflow: 'hidden' }}>
          <thead>
            <tr>
              <th
                style={{ cursor: 'default' }}
                onClick={async () => {
                  await getHistoryTrades(selectedDay);
                }}
              >TRADES</th>
              <th>ENTRY</th>
              <th>IN</th>
              <th>OUT</th>
              <th>[%]</th>
              <th>[USDT]</th>
            </tr>
          </thead>
          <tbody>
            {historyTrades.map((item) => {
              return <tr key={item.id} >
                <td
                  style={{ color: item.position_side === 'LONG' ? '#26a69a' : '#f44336', cursor: 'default', border: props.futureSymbol === item.symbol ? '1px solid yellow' : undefined }}
                  onClick={async () => {
                    setClickedTradeId(item.id);
                    setclickedDate(new Date());
                    await changeSymbol(props, item.symbol);
                  }}

                ><b>{item.symbol}</b></td>
                <td >{item.entry_timestamp}</td>
                <td >{item.entry_order_price}</td>
                <td>{item.close_order_price} </td>
                <td>{item.price_change_percentage} </td>
                <td style={{ color: item.trade_profit >= 0 ? '#26a69a' : '#f44336' }}><b>{item.trade_profit} </b></td>
              </tr>;
            })}
          </tbody>
        </Table>

      </Container>
    </Fragment >
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
