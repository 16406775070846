import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../../apis';
import CONFIG from '../../../configs/index';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { mapStateToProps, mapDispatchToProps } from '../../../redux';

const AmountPercentage = (props) => {
  useEffect(() => {
    props.setAmountPercentage(Number(localStorage.getItem('amount_percentage')) || CONFIG.AMOUNT_PERCENTAGE.DEFAULT);
  }, []);

  return (
    <Fragment>
      <ButtonGroup style={{ width: '100%' }} >
        {CONFIG.AMOUNT_PERCENTAGE.LIST.map((item, idx) => {
          return <ToggleButton
            key={idx}
            size='sm'
            id={`amount-percentage-${idx}`}
            type='radio'
            variant='outline-light'
            value={item}
            checked={props.amountPercentage === item}
            onChange={(e) => {
              props.setAmountPercentage(item);
              localStorage.setItem('amount_percentage', item);
            }}
          >
            {item}%
          </ToggleButton>;
        })}
      </ButtonGroup>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AmountPercentage);
