import React from 'react';
import Home from '../Home';
import SignUp from '../SignUp';
import Future from '../Future';
import Chart from '../Chart';
import './style.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div className="root">
        <Switch>
          <Route path="/chart">
            <Chart />
          </Route>

          <Route path="/trading">
            <Future />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/">
            <Home />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}

