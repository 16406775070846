import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import apis from '../../apis';
import { Modal, Dropdown, Tabs, Tab, ToggleButton, Col, Row, Table, ButtonGroup, DropdownButton, InputGroup, FormControl, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCloudUploadAlt, faSyncAlt, faExpand, faCogs, faEraser, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CONFIG from '../../configs/index';
import { mapStateToProps, mapDispatchToProps } from '../../redux';
import { changeSymbol } from '../../utils';


const SystemInfo = (props) => {
  const [info, setInfo] = useState({});
  useEffect(() => {
    (async () => {
      await refresh();
    })();

    setInterval(async () => {
      await refresh();
    }, 1000);
  }, []);

  const refresh = async () => {
    setInfo(await apis.dataSync('api_trading_get_system_info'));
  };

  return (
    <Fragment>
      <Container>
        <Table bordered hover variant='dark' size='sm' >
          <tbody>
            {Object.keys(info).map((key, value) => {
              return <tr key={key} >
                <td><b>{key.toUpperCase()}</b></td>
                <td>{info[key]}</td>
              </tr>;
            })}
          </tbody>
        </Table>

      </Container>
    </Fragment >
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(SystemInfo);
